import { useTheme } from "@up/data";

import { UPLink } from "./up-link";

export const PageError = function PageError() {
  const theme = useTheme();

  return (
    <div className="mx-auto max-w-[800px] pl-8 pr-8">
      <div className="lg:pb-18 lg:pt-18 pb-12 pt-12 text-gray-900">
        <h1 className={theme.heading1Class}>Sorry, an error has occurred</h1>
        <br />
        <ul className="list-disc">
          <li className="mb-4">
            Please{" "}
            <button
              className="underline"
              onClick={() => {
                window.location.reload();
              }}
            >
              reload the page
            </button>{" "}
            to try again
          </li>
          <li className="mb-4">
            If you keep seeing this error,{" "}
            <a className="underline" href={`mailto:${theme.supportEmail}`}>
              email support
            </a>
          </li>
          <li className="mb-4">
            To start from the beginning,{" "}
            <UPLink className="underline" href="/">
              go to the home page
            </UPLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
